import { defineStore } from 'pinia'

export interface LapPositionState {
  lap: number,
  showLap: boolean,
  position: string,
  showPosition: boolean
}

const initialState = (): LapPositionState => ({
  lap: 0,
  showLap: false,
  position: '',
  showPosition: false
})

export const lapPositionState = defineStore('lapPositionState', {
  state: initialState
})
