import { defineStore } from 'pinia'

export interface TimerState {
  time: string
}

const initialState = (): TimerState => ({
  time: '0.0'
})

export const timeState = defineStore('timeState', {
  state: initialState
})
