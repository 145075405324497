import { defineStore } from 'pinia'

export interface StartPhaseState {
  clickedPower: number,
  soundTimer: number,
  showName: boolean,
  showPhase: boolean,
  attempt: number,
  showShine: boolean
}

const initialState = (): StartPhaseState => ({
  clickedPower: 0,
  soundTimer: 0,
  showName: false,
  showPhase: false,
  attempt: 0,
  showShine: false
})

export const startPhaseState = defineStore('startPhaseState', {
  state: initialState
})
