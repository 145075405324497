import {
  type AppWSM2021Data,
  ModeTypes
} from '@powerplay/core-minigames'

/**
 * Konfig appky WSM 2021 pre vsetky obtiaznosti
 */
export const appWSM2021AllDifficultiesConfig: AppWSM2021Data = {
  [ModeTypes.wsm2021easy]: {
    players: [
      {
        uuid: '',
        name: 'Jens Andre Carlsen',
        country: 'nor',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 66.42,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Franz Mayer',
        country: 'aut',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 65.61,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Gerd Becker',
        country: 'deu',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 67.6,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Giovanni Vivaldi',
        country: 'ita',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 66.85,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Antoine Cabor',
        country: 'fra',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 67.22,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Stefan Jacobs',
        country: 'che',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 66.07,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Richard Ryder',
        country: 'usa',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 68.35,
            second: [0]
          }
        ]
      }
    ],
    specialData: { }
  },
  [ModeTypes.wsm2021medium]: {
    players: [
      {
        uuid: '',
        name: 'Jens Andre Carlsen',
        country: 'nor',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 62.42,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Franz Mayer',
        country: 'aut',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 61.61,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Gerd Becker',
        country: 'deu',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 63.6,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Giovanni Vivaldi',
        country: 'ita',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 62.85,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Antoine Cabor',
        country: 'fra',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 63.22,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Stefan Jacobs',
        country: 'che',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 62.07,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Richard Ryder',
        country: 'usa',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 64.35,
            second: [0]
          }
        ]
      }
    ],
    specialData: { }
  },
  [ModeTypes.wsm2021hard]: {
    players: [
      {
        uuid: '',
        name: 'Jens Andre Carlsen',
        country: 'nor',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 58.42,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Franz Mayer',
        country: 'aut',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 57.61,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Gerd Becker',
        country: 'deu',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 59.6,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Giovanni Vivaldi',
        country: 'ita',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 58.85,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Antoine Cabor',
        country: 'fra',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 59.22,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Stefan Jacobs',
        country: 'che',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 58.07,
            second: [0]
          }
        ]
      },
      {
        uuid: '',
        name: 'Richard Ryder',
        country: 'usa',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 300,
        resultsArr: [
          {
            main: 60.35,
            second: [0]
          }
        ]
      }
    ],
    specialData: { }
  }
}
