import {
  trainingManager,
  modes,
  requestManager
} from '@powerplay/core-minigames'
import { Tasks } from '../../types'
import { player } from '@/app/entities/athlete/player'
import {
  trainingConfig,
  velocityConfig
} from '@/app/config'
import { trainingState } from '@/stores'

/**
 * Trieda pre treningove ulohy
 */
export class TrainingTasks {

  /** Mnozstvo taskov */
  private NUMBER_OF_TASKS = 4

  /** maximalna dosiahnuta rychlost */
  private maxSpeedAchieved = 0

  /** potrebna max rychlost */
  private maxSpeedReq = 0

  /** slipstream kvalita */
  private slipStreamQuality = 0

  /** predbiehanie kvalita */
  private overtakingQuality = 0

  /**
   * Inicializovanie treningu
   */
  public initTraining(): void {

    trainingManager.setNumberOfTasks(this.NUMBER_OF_TASKS)

  }

  /**
   * Vypocet maxSpeedReq
   */
  public setMaxSpeedReq(): void {

    this.maxSpeedReq = player.speedManager.minSpeed +
      player.speedManager.cruiseSpeedConst +
      player.speedManager.sprintSpeedConst +
      velocityConfig.slipstreamBonus

  }

  /**
   * Hlavna metoda ktoru volame pri pouziti trainingovych Taskov
   * @param task - Typu Tasks, typ ulohy ktorej hodnoty sa zadavaju
   * @param valueToCount - hodnota ktora sa zadava
   */
  public countTaskValue(task: Tasks, valueToCount: number): void {

    if (!modes.isTrainingMode()) return

    if (task === Tasks.maxSpeed) this.countMaxSpeedQuality(valueToCount)
    if (task === Tasks.splitStream) this.countSlipStreamQuality(valueToCount)
    if (task === Tasks.overtaking) this.countOvertakingQuality(valueToCount)

  }

  /**
   * Pocitanie overtakingQuality
   * @param numberOfOvertaken - pocet obehnutych
   */
  private countOvertakingQuality(numberOfOvertaken: number): void {

    if (this.overtakingQuality >= 1) return

    this.overtakingQuality += numberOfOvertaken * trainingConfig.overtakingPercent
    this.overtakingQuality = Math.floor(this.overtakingQuality * 10) / 10
    this.overtakingQuality = Math.min(1, this.overtakingQuality)
    this.saveTaskValue(Tasks.overtaking, this.overtakingQuality)

  }

  /**
   * Pocitanie maxSpeedQuality
   * @param maxSpeedAchieved - dosiahnuta rychlost
   */
  private countMaxSpeedQuality(maxSpeedAchieved: number): void {

    if (this.maxSpeedAchieved >= maxSpeedAchieved) return

    this.maxSpeedAchieved = maxSpeedAchieved
    const maxSpeedQuality = Math.round((maxSpeedAchieved - player.speedManager.minSpeed) /
      (this.maxSpeedReq - player.speedManager.minSpeed) * 100) / 100

    console.log(maxSpeedAchieved, this.maxSpeedReq, maxSpeedQuality)
    this.saveTaskValue(Tasks.maxSpeed, maxSpeedQuality)

  }

  /**
   * Pocitanie slipstream quality
   * @param valueToCount - hodnota ktora sa zadava
   */
  private countSlipStreamQuality(valueToCount: number): void {

    if (this.slipStreamQuality >= 1) return
    this.slipStreamQuality += valueToCount
    this.slipStreamQuality = Math.min(1, this.slipStreamQuality)
    this.saveTaskValue(Tasks.splitStream, this.slipStreamQuality)

  }

  /**
   * Hlavna privatna metoda, ktora riesi UI zmeny a trainingManager classu
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  public saveTaskValue(name: Tasks, value: number): void {

    if (!modes.isTrainingMode()) return

    value = Math.ceil(value * 10000) / 10000

    this.changeUI(name, value)
    trainingManager.setTaskValue(name, value)

  }

  /**
   * Zmena UI
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  private changeUI(name: Tasks, value: number) {

    if (!modes.isTrainingMode()) return
    console.log(`UI zmeny ${name} ma hodnotu ${value}`)
    const percent = Math.ceil(value * 100)
    const tpTask = Math.ceil(Math.round((trainingManager.getTpPerTask() * value * 10) + Number.EPSILON) / 10)
    console.log('tp task:', tpTask)

    if (tpTask !== undefined) {

      trainingState().editTask({
        text: `trainingTask${requestManager.disciplineID}-${name}`,
        percent: String(percent),
        points: String(tpTask),
        showPoints: true
      })

    }

  }

  /**
   * Reset
   */
  public reset(): void {

    console.log('RESET TRAINING')
    trainingManager.resetAll()

    this.maxSpeedAchieved = 0
    this.maxSpeedReq = 0
    this.slipStreamQuality = 0
    this.overtakingQuality = 0

  }

}

export const trainingTasks = new TrainingTasks()
