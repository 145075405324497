import {
  gameConfig,
  pathsConfig
} from '@/app/config'
import {
  game,
  THREE
} from '@powerplay/core-minigames'

/**
 * Lines Manager
 * Trieda spravujuca drahy po ktorych behaju hraci
 */
export class LinesManager {

  /** dostupne drahy */
  private playerPaths: THREE.CurvePath<THREE.Vector3>[] = []

  /** raycast z hraca */
  private debugRaycast = new THREE.Raycaster()

  /** Pomocny vektor */
  private helperVector = new THREE.Vector3()

  /** Vektor hore */
  private readonly UP_VECTOR = new THREE.Vector3(0, 1, 0)

  /**
   * init
   */
  public init(): void {

    const gameObjectWithLines = new THREE.Group()

    const { tracks } = pathsConfig

    for (let i = 1; i <= tracks; i++) {

      const pathName = `TrackLine_${i}`
      const trackPlayer = game.getObject3D(pathName)
      trackPlayer.visible = false
      this.playerPaths[i] = new THREE.CurvePath<THREE.Vector3>()
      this.setupCurvePathFromObject(trackPlayer as THREE.LineSegments, this.playerPaths[i])

      const guideline = game.getObject3D(`trackLines_TrackLineGuide_${i}`)
      guideline.visible = false
      gameObjectWithLines.add(trackPlayer)

      game.scene.add(gameObjectWithLines)

    }

  }

  /**
   * @param index - index drahy
   * @returns - drahu, ak je validny index
   */
  public getPath(index: number): THREE.CurvePath<THREE.Vector3> | undefined {

    if (index <= 0) return undefined
    if (index >= this.playerPaths.length) return undefined

    return this.playerPaths[index]

  }

  /**
   * Vytvorenie curvePath z modelu
   * @param object - objekt s krivkou
   * @param curvePath - objekt, kde ulozime curvePath
   */
  private setupCurvePathFromObject(
    object: THREE.LineSegments,
    curvePath: THREE.CurvePath<THREE.Vector3>
  ): void {

    const pointsArray: number[] = Array.from(object.geometry.attributes.position.array)
    const coordinates: (THREE.Vector3 | undefined)[] = pointsArray
      .map((_: number, idx: number, origArray) => {

        if (idx % 3 !== 0) {

          return undefined

        }
        return new THREE.Vector3(
          origArray[idx],
          origArray[idx + 1],
          origArray[idx + 2],

        )

      }).filter(e => e !== undefined)
    coordinates.push(coordinates[0]?.clone())
    if (coordinates.includes(undefined)) {

      // error
      return

    }

    const lastPoint = new THREE.Vector3()
    coordinates.forEach((point, index) => {

      if (point === undefined) return
      if (index === 0) console.log(point)
      if (index > 0) {

        curvePath.add(new THREE.LineCurve3(
          new THREE.Vector3(lastPoint.x, lastPoint.y, lastPoint.z),
          new THREE.Vector3(point.x, point.y, point.z)
        ))

      }

      lastPoint.copy(point)

    })

  }

  /**
   * Vytovrenie debug bodov podla typu triggeru
   * @param type - Typ triggera
   * @param newConfig - novy config pre zobrazenie
   */
  public debugPointsTrigger(type = 'finish', newConfig: number[] = []): void {

    let config = pathsConfig.positionsFinish
    if (newConfig.length > 0) config = newConfig

    const geometrySphere = new THREE.SphereGeometry(0.2, 0.2, 0.2)
    const materialSphere = new THREE.MeshBasicMaterial({ color: 0x00FF00 })

    this.playerPaths.forEach((value, index) => {

      const meshSphere = new THREE.Mesh(geometrySphere, materialSphere)
      const position = value.getPointAt(type === 'start' ? pathsConfig.positionStart : config[index - 1])
      meshSphere.position.set(position.x, position.y, position.z)
      meshSphere.name = `${index}_${type}_debug`
      game.scene.add(meshSphere)

    })

  }

  /**
   * Vypocitanie configu podla percenta na jednej trati
   * @param percent - percento drahy
   * @param index - index drahy
   */
  public getConfigFromOnePoint(percent: number, index: number): number[] {

    const oneMetersInPercent = [] as number[]
    for (let i = 1; i < this.playerPaths.length; i++) {

      oneMetersInPercent[i - 1] = 1 / this.playerPaths[i]?.getLength() || 0

    }

    const meters = 1 / oneMetersInPercent[index] * percent
    const config = [] as number[]

    for (let i = 0; i < this.playerPaths.length - 1; i++) {

      config[i] = oneMetersInPercent[i] * meters

    }

    console.log(config)
    return config

  }

  /**
   * Odstranenie debug bodov podla typu triggeru
   * @param type - Typ triggera
   */
  public removeDebugPointsTrigger(type = 'finish'): void {

    this.playerPaths.forEach((_value, index) => {

      const mesh = game.getMesh(`${index}_${type}_debug`)
      mesh.name += '_removed'
      mesh.visible = false

    })

  }

  /**
   * Getter
   * @returns playerPaths
   */
  public getPaths(): THREE.CurvePath<THREE.Vector3>[] {

    return this.playerPaths

  }

  /**
   * Tool na pocitanie percent pre vsetky drahy z percenta na jednej
   * @param percent - percento na hlavnej drahe
   * @param pathIndex - index hlavnej drahy
   */
  public getPercentsOnAllPathsFromOne(percent: number, pathIndex = 3): number[] {

    const position = this.playerPaths[pathIndex].getPointAt(percent)
    position.y += gameConfig.yPlayerCorrection
    let p = percent
    if (percent + 0.0016 > 1) p -= 1
    const lookAt = this.playerPaths[pathIndex].getPointAt(p + 0.0016)
    lookAt.setY(position.y)

    const resultPercents = []

    const trackNumbers = [0, 1, 2, 3]
    for (let i = 1; i <= trackNumbers.length; i++) {

      if (i === pathIndex) {

        resultPercents.push(percent)
        continue

      }
      let toRight = false
      if (i > pathIndex) toRight = true

      this.helperVector.set(0, 0, 0)
      this.helperVector.subVectors(lookAt, position).normalize()

      let angle = Math.PI / 2
      if (!toRight) angle *= -1

      this.helperVector.applyAxisAngle(this.UP_VECTOR, angle)

      this.debugRaycast.set(position, this.helperVector)
      /*
       * const debugArrow =  new THREE.ArrowHelper(
       *     this.debugRaycast.ray.direction,
       *     this.debugRaycast.ray.origin,
       *     2,
       *     Math.random() * 0xffffff
       * )
       * game.scene.add(debugArrow)
       */


      // zoberiem si bod, kde sa pretal s krivkou kde chcem ist
      const intersectionPoint = this.debugRaycast
        .intersectObject(game.getObject3D(`TrackLine_${i}`))?.[0]?.point

      if (intersectionPoint === undefined) continue
      intersectionPoint.setY(0)

      // zoberiem si z tej krivky x bodov pred aktualnym percentom a x bodov po aktualnom percente
      const pointsToSearch = this.getPointsToSearch(percent, i)

      // prejdem tento zoznam a najdem najblizsi bod,
      let distance: number | undefined = undefined
      let lowestDistancePointIndex = 0
      pointsToSearch.forEach((point, index) => {

        const newDistance = point.distanceTo(intersectionPoint)

        if (distance === undefined || newDistance < distance) {

          distance = newDistance
          lowestDistancePointIndex = index

        }

      })

      const { percentDiff, pointsPerPercent } = pathsConfig.changePathConfig
      const newPercent = percent +
                (lowestDistancePointIndex / pointsPerPercent - percentDiff)
      resultPercents.push(newPercent)

    }

    const geometrySphere = new THREE.SphereGeometry(0.1, 0.1, 0.1)

    resultPercents.forEach((result, i) => {

      let r = result
      if (r < 0) r += 1
      const spherePosition = this.playerPaths[i + 1].getPointAt(r)
      console.log(this.playerPaths, i, spherePosition, this.playerPaths[i + 1], r)
      const name = `${i + 1}_percent_debug`

      const sphere = game.scene.getObjectByName(name)

      if (sphere) {

        sphere.position.set(spherePosition.x, spherePosition.y + gameConfig.yPlayerCorrection, spherePosition.z)
        return

      }

      const materialSphere = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff })
      const meshSphere = new THREE.Mesh(geometrySphere, materialSphere)

      meshSphere.position.set(spherePosition.x, spherePosition.y + gameConfig.yPlayerCorrection, spherePosition.z)
      meshSphere.name = name
      game.scene.add(meshSphere)

    })

    console.warn('Percenta: ', resultPercents)

    return resultPercents

  }

  /**
   * Debug pre jeden bod na jednej drahe
   * @param percent - percento na drahe
   * @param pathIndex - index drahy
   */
  public showPoint(percent: number, pathIndex = 3): void {

    const geometrySphere = new THREE.SphereGeometry(0.1, 0.1, 0.1)

    const spherePosition = this.playerPaths[pathIndex].getPointAt(percent)
    const name = `${pathIndex}_point_debug`

    const sphere = game.scene.getObjectByName(name)

    if (sphere) {

      sphere.position.set(spherePosition.x, spherePosition.y + gameConfig.yPlayerCorrection, spherePosition.z)
      return

    }

    const materialSphere = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff })
    const meshSphere = new THREE.Mesh(geometrySphere, materialSphere)

    meshSphere.position.set(spherePosition.x, spherePosition.y + gameConfig.yPlayerCorrection, spherePosition.z)
    meshSphere.name = name
    game.scene.add(meshSphere)


  }

  /**
   * Vyberie body vyseku drahy s frekvenciou podla configu
   * @param percent - percento na hlavnej drahe
   * @param pathIndex - index hlavnej drahy
   * @returns pointsToSearch - body na vyseku drahy
   */
  private getPointsToSearch(percent: number, pathIndex: number): THREE.Vector3[] {

    const pointsToSearch: THREE.Vector3[] = []
    const { percentDiff, pointsPerPercent } = pathsConfig.changePathConfig

    for (let i = 0; i < percentDiff * 2 * pointsPerPercent; i++) {

      const point = this.playerPaths[pathIndex].getPointAt(percent + (i / pointsPerPercent - percentDiff))
      if (point) {

        point.setY(0)
        pointsToSearch.push(point)

      } else {

        pointsToSearch.push(new THREE.Vector3())

      }

    }
    return pointsToSearch

  }

}
export const linesManager = new LinesManager()