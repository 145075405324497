<template>
  <section
    class="positioner"
  >
    <fps-looker />
  </section>
</template>

<script lang="ts">
import { FpsLooker } from '@powerplay/core-minigames-ui-ssm'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FpsLookerComponent',
  components: {
    FpsLooker
  }
})

</script>

<style lang="less" scoped>
  .positioner {
    position: absolute;
    left: 0;
    top: 0;
    width: 30%;
    height: 10%;
    pointer-events: auto;
  }
</style>
