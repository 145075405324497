import {
  actionButtonState,
  inputsState,
  movementState,
  textMessageState,
  uiState
} from '@/stores'
import { modes } from '@powerplay/core-minigames'

export class StartPhaseStateManager {

  private textMessagesLinesFinishedEmits = [false, false]

  public disableInputs(): void {

    inputsState().disabled = true
    actionButtonState().disabled = true

  }

  public enableInputs(disabled = false): void {

    inputsState().isVisible = true
    actionButtonState().disabled = disabled

  }

  public postIntroUiState(): void {

    uiState().$patch({
      showTimeKeeper: false,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })
    movementState().onlyHorizontal = true
    inputsState().disabled = false

  }

  public finishPhaseUiState(): void {

    uiState().$patch({
      showTimeKeeper: false,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

  }

  public enableStartInputs(): void {

    console.log('enable start inputs')
    inputsState().disabled = false
    actionButtonState().$patch({
      isStart: true,
      disabled: false
    })

  }

  /**
   * Schovanie text message hlasky
   * @param lineNumber - Cislo riadku, ktore malo emit konca
   */
  public hideTextMessage(lineNumber: number): void {

    this.textMessagesLinesFinishedEmits[lineNumber - 1] = true

    if (this.textMessagesLinesFinishedEmits[0] && this.textMessagesLinesFinishedEmits[1]) this.hideAllTextMessages()

  }

  /**
     * Schovanie vsetkych message hlasok
     */
  public hideAllTextMessages(): void {

    textMessageState().$patch({
      showFinalLap: false,
      showCountdown: false,
      showType: 0
    })

  }

  /**
     * Resetovanie text message dokoncenych emitov
     * @param activeFirst - ci je aktivny prvy riadok
     * @param activeSecond - ci je aktivny druhy riadok
     */
  public resetTextMessageFinishedEmits(activeFirst: boolean, activeSecond: boolean): void {

    this.hideAllTextMessages()
    this.textMessagesLinesFinishedEmits = [!activeFirst, !activeSecond]

  }

}

export const startPhaseStateManager = new StartPhaseStateManager()
