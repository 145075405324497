import {
  THREE,
  AnimationsManager,
  game,
  fpsManager,
  playersManager,
  gsap,
  corePhasesManager,
  CorePhases,
  timeManager,
  minigameConfig,
  modes,
  trainingManager
} from '@powerplay/core-minigames'
import {
  gameConfig,
  animationsConfig,
  pathsConfig,
  playerAnimationConfig
} from '../../config'
import {
  DisciplinePhases,
  PlayerStates,
  EmotionTypesFinish,
  Sides,
  PlayerAnimationsNames,
  CustomGameEvents
} from '../../types'
import {
  AthleteAnimationManager,
  AthleteSpeedBarManager
} from '.'
import { WorldEnvLinesCreator } from '../env/WorldEnvLinesCreator'
import { SpeedManager } from '@/app/SpeedManager/SpeedManager'
import { endManager } from '@/app/EndManager'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { Bicycle } from './Bicycle'
import { AthleteSprintBarManager } from './AthleteSprintBarManager'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { HelperMan } from './HelperMan'
import {
  blockingState,
  lapPositionState
} from '@/stores'

/**
 * Trieda pre atleta
 */
export class Athlete {

  /** 3D objekt lyziara - cela scena */
  public athleteObject = new THREE.Object3D()

  /** Zakladny animacny manager */
  public animationsManager!: AnimationsManager

  /** Manager pre animacne stavy */
  public athleteAnimationManager = new AthleteAnimationManager(this)

  /** Manager speed baru */
  public speedBarManager = new AthleteSpeedBarManager(this)

  /** Manager sprint baru */
  public sprintBarManager = new AthleteSprintBarManager(this)

  /** manager rychlosti */
  public speedManager = new SpeedManager()

  /** bicykel */
  public bicycle = new Bicycle(this)

  /** Objekt pre pomocnika cyklistu */
  private helperMan = new HelperMan(this)

  /** Stav hraca */
  private state = PlayerStates.prepare

  /** Ci je aktivne aktualizovanie pohybovych animacii */
  public activeUpdatingMovementAnimations = false

  /** Posledna pozicia atleta */
  public lastAthletePosition = new THREE.Vector3()

  /** dolezity manager na pohyb */
  public worldEnvLinesManager!: WorldEnvLinesCreator

  /** Ci ide o hratelneho atleta alebo nie */
  public playable = false

  /** Emocia v cieli */
  public emotionFinish = EmotionTypesFinish.looser

  /** Kolko frameov treba pockat, aby sa nastavil stav konca */
  private framesToWaitForEndState = 0

  /** Ci sa maju aktualizovat framey (mali by sa po starte) */
  private updateFramesAfterStart = false

  /** Pocet frameov po starte */
  private framesAfterStart = 0

  /** Cas v cieli */
  public finalTime = minigameConfig.dnfValue

  /** True ak prave meni drahu */
  public isChangingPath = false

  /** ci je v zavetri */
  public isInSlipStream = false

  /** hrac za ktorym je moc blizko */
  public inSafeZoneOfAthlete: Athlete | undefined

  /** Atlet za ktorym som, ak som v slipstreame */
  public athleteOfSlipStream?: Athlete

  /** hrac za ktorym je moc blizko v poslednom kole */
  public inSafeZoneOfAthleteLastLap: Athlete | undefined

  /** startovacia pozicia */
  public startingOrder = 0

  /** ci presiel cielom */
  public isEnd = false

  /** lerp naklanania */
  public leanOnLerp = new THREE.Vector3()

  /** ci je 200m pred cielom */
  public last200m = false

  /** vzdialenost od prveho hraca ak 0, tak som prvy hrac */
  public reactionDistance = 0

  /** priebezne poradie */
  public provisionalPosition = 0

  /** pozicia v ktorej prisiel do ciela od 0 */
  public positionOnFinish = 0

  /** ci sprintuje */
  public isSprinting = false

  /** Index pre animacie prepare */
  public animationPrepareChainIndex = 0

  /** ako casto pocitat ci sa ma pozriet dozadu */
  private lookBackFrames = 0

  /**
   * Konstruktor
   * @param uuid - UUID spera
   */
  public constructor(public uuid = '') {}

  /**
   * Nastavenie stavu atleta
   * @param state - stav
   * @param maxRandomDelay - maximalna hodnota randomu delayu od 0
   */
  public setState(state: PlayerStates, maxRandomDelay = 0): void {

    const random = THREE.MathUtils.randFloat(0, maxRandomDelay)

    if (random === 0) {

      this.state = state

    } else {

      gsap.to({}, {
        duration: random,
        callbackScope: this,
        onComplete: () => {

          this.state = state
          console.log('state random', state, this.uuid, random)

        }
      })

    }



  }

  /**
   * Zistenie ci je aktualne dany stav
   * @param state -stav
   * @returns True, ak ano
   */
  public isState(state: PlayerStates): boolean {

    return this.state === state

  }

  /**
   * Zistenie ci je aktualny jeden z danych stavov
   * @param states - mozne stavy
   * @returns True, ak ano
   */
  public isOneOfStates(states: PlayerStates[]): boolean {

    return states.includes(this.state)

  }

  /**
   * Getter pre aktualne percento na drahe
   * @returns - actualPercent
   */
  public getActualPercentOnPath(): number {

    return this.worldEnvLinesManager.getActualPercent()

  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    // mechanika bude v zdedenej classe
    return new THREE.Object3D

  }

  /**
   * Vytvorenie lyziara
   * @param trackIndex - Index drahy
   * @param startingOrder - pozicia pri starte
   * @param animationPrepareChainIndex - Index pre retaz kombinacie prepare animacii
   * @param athleteName - Meno alteta
   * @param nameForAnimations - Meno pre ziskanie animacii
   */
  public create(
    trackIndex: number,
    startingOrder: number,
    animationPrepareChainIndex: number,
    athleteName: string,
    nameForAnimations: string
  ): void {

    console.warn(this.uuid, trackIndex, startingOrder)
    this.animationPrepareChainIndex = animationPrepareChainIndex
    this.athleteAnimationManager.prepareAnimation = playerAnimationConfig
      .prepareAnimationsChains[this.animationPrepareChainIndex][0] as PlayerAnimationsNames
    this.startingOrder = startingOrder
    this.playable = playersManager.getPlayerById(this.uuid)?.playable || false
    this.athleteObject = this.getObject()
    game.scene.add(this.athleteObject)

    if (!this.playable) this.helperMan.create()

    // animacie
    this.animationsManager = new AnimationsManager(
      this.athleteObject,
      animationsConfig,
      game.animations.get(nameForAnimations),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()

    // toto len aby fungovali uvodne nastavovacky, aj tak sa to potom poposuva
    const position = gameConfig.start.position.clone()
    position.y += gameConfig.start.yOffset * this.startingOrder
    position.z -= gameConfig.start.zOffset * this.startingOrder

    // threeJS Section
    this.athleteObject.position.set(position.x, position.y, position.z)
    this.athleteObject.name = athleteName

    console.log('atlet vytvoreny pre trat: ', trackIndex)

    this.worldEnvLinesManager = new WorldEnvLinesCreator(
      trackIndex,
      (actualPos: number, lastPos: number, finishPos: number) => {

        this.finishReached(actualPos, lastPos, finishPos)

      },
      this
    )

    this.bicycle.create()
    this.speedManager.setMinSpeed(
      playersManager.getPlayerById(this.uuid)?.attribute.total || 0,
      this.uuid,
      this.playable
    )

    this.sprintBarManager.setSprintBarDecreaseRate()

    this.lookBackFrames = THREE.MathUtils.randInt(
      playerAnimationConfig.lookBackFrames.min,
      playerAnimationConfig.lookBackFrames.max
    )
    console.log('look back frames... ', this.lookBackFrames, this.uuid)

  }

  /**
   * Zmena drahy hraca
   * @param side - strana zmeny drahy
   * @returns ci sa podarilo odbocit
   */
  public changePath(side: Sides): boolean {

    if (modes.isTutorial()) {

      if (this.playable) {

        if (!tutorialFlow.slipStreamSuccess) return false
        tutorialFlow.changePathTween?.kill()

      } else {

        if (!tutorialFlow.requiredSpeedEventTriggered) return false

      }

    }
    if (this.speedBarManager.inputsBlocked) return false
    let validChange = false
    // ci sa moze menit draha (napr pri rozbehu sa nemoze)
    const canChange = !this.isChangingPath

    if (canChange) {

      let index = this.worldEnvLinesManager.pathIndex - 1

      if (side === Sides.RIGHT) {

        index = this.worldEnvLinesManager.pathIndex + 1

      }
      validChange = this.worldEnvLinesManager.changePath(index)

    }

    if (this.playable && canChange && !validChange) {

      this.showBlockingEffect(side)

    }
    if (!validChange) {

      if (this.playable) {

        disciplinePhasesManager.phaseRunning.switchLaneBlocker = false

      }

      return false

    }

    this.isChangingPath = true
    // if (this.playable) {

    //   tutorialFlow.eventActionTrigger(TutorialEventType.changePathSuccess)

    // }
    return true

  }

  /**
   * Zobrazenie blocking grafiky
   * @param _side - strana narazu
   */
  public showBlockingEffect(_side: Sides | undefined): void {

    // v potomkoch
    console.log(_side)

  }

  /**
   * Odstartovanie hraca
   */
  public start(): void {

    console.log('odstartoval atlet', this.uuid)
    this.athleteAnimationManager.stopPrepareAnimations()
    this.setState(PlayerStates.tempo)
    this.speedManager.setActualSpeed(13.5)
    this.speedManager.setActive(true)
    this.updateFramesAfterStart = true
    this.framesAfterStart = 0
    this.worldEnvLinesManager.changePathTo(1)
    this.worldEnvLinesManager.setActualPercentOnStart()
    this.helperMan.setVisibility(false)

    if (modes.isTutorial()) {

      this.athleteAnimationManager.startTempo()

    }

  }

  /**
   * Spravenie veci po tom, co su vytvoreni superi
   */
  public afterOpponentsCreated(): void {

    this.helperMan.create()
    game.getMesh('helperMan_pomocnik').visible = false

    const athleteOpponentObject = game.scene.getObjectByName('athlete_opponent')
    if (athleteOpponentObject) athleteOpponentObject.visible = false

  }

  /**
   * Nastavenie viditelnosti cyklistu
   */
  public setVisibilityAthlete(visible: boolean): void {

    this.athleteObject.visible = visible

  }

  /**
   * Aktualizovanie pozicie lyziara
   * @param positionObj - Pozicia
   * @param actualPhase - aktualna faza
   */
  private updatePlayerPosition(positionObj: THREE.Object3D, actualPhase: DisciplinePhases): void {

    const instalerp = [DisciplinePhases.start, DisciplinePhases.preStart].includes(actualPhase) ||
    disciplinePhasesManager.phaseRunning.needsInstalerpForStart()

    let t = 0.5
    if (instalerp) t = 1

    if (disciplinePhasesManager.getActualPhase() >= DisciplinePhases.running) {

      this.athleteObject.position.lerp(positionObj.position, t)

    }

    this.athleteObject.quaternion.slerp(positionObj.quaternion, t)

  }

  /**
   * Vratenie rotacie lyziara
   * @returns Quaternion lyziara
   */
  public getQuaternion(): THREE.Quaternion {

    return this.athleteObject.quaternion

  }

  /**
   * Vratenie pozicie atleta
   * @returns Pozicia atleta
   */
  public getPosition(): THREE.Vector3 {

    return this.athleteObject.position

  }

  /**
   * Aktualizovanie hraca po vykonani fyziky
   */
  public updateAfterPhysics(): void {

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.end) return
    if (!corePhasesManager.isActivePhaseByType(CorePhases.discipline)) return

    // velocity manager sa riesi iba ked je aktivna rychlost, inak je to neziaduce
    if (this.speedManager.isActive()) this.speedBarManager.update()
    this.speedManager.update(this.speedBarManager.getSpeedPower(), this)

    // kontrola a spustenie stavu konca
    this.manageEnd()

    this.athleteAnimationManager.update()

    if (this.updateFramesAfterStart) {

      this.framesAfterStart += 1

      if (this.framesAfterStart % 30 === 0) {

        // const frame = this.framesAfterStart
        // const percent = this.worldEnvLinesManager.getActualPercent()
        // const meters = (percent - percentStart) * this.worldEnvLinesManager.totalPathLength
        // console.log(`atlet ${this.uuid} presiel vo frame ${frame} tolkoto metrov: ${meters}`)
        console.log(`${this.uuid}
rychlost: ${this.speedManager.getActualSpeed()},
speedbar: ${this.speedBarManager.getSpeedPower()},
sprintbar: ${this.sprintBarManager.sprintBarValue},
speedbarMax: ${this.speedBarManager.speedBarMax}`)

      }

      const positionToLookBack = modes.isEventBossFight() ? 1 : 2
      if (
        this.provisionalPosition < positionToLookBack &&
        this.framesAfterStart % this.lookBackFrames === 0 &&
        this.state === PlayerStates.tempo &&
        this.worldEnvLinesManager.pathIndex <= 2 &&
        Math.random() <= playerAnimationConfig.lookBackPercent / 100
      ) {

        this.setState(PlayerStates.lookBack)

      }

    }

    if (!this.playable || this.isEnd) return
    lapPositionState().$patch({
      position: gameConfig.positionDictionary[this.provisionalPosition],
      lap: this.worldEnvLinesManager.lap
    })

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   * @param actualPhase - Aktualna faza
   */
  public updateBeforePhysics(actualPhase: DisciplinePhases): void {

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.end) return
    const point = this.worldEnvLinesManager.update(this.speedManager)
    this.updatePlayerPosition(point, actualPhase)
    this.manageLeanOn()

    if (!corePhasesManager.isActivePhaseByType(CorePhases.discipline)) return

  }

  /**
   * Naklananie
   */
  public manageLeanOn(): void {

    const rotation = this.worldEnvLinesManager.leanOnActive ?
      pathsConfig.leanOnAngles.active :
      pathsConfig.leanOnAngles.inactive

    const instalerp = disciplinePhasesManager.phaseRunning.needsInstalerpForStart() ||
      [DisciplinePhases.start, DisciplinePhases.preStart].includes(disciplinePhasesManager.actualPhase)

    this.leanOnLerp.lerp(rotation, instalerp ? 1 : 0.04)
    this.athleteObject.rotateZ(this.leanOnLerp.z)
    blockingState().leanOn = this.leanOnLerp.z / pathsConfig.leanOnAngles.active.z


  }

  /**
   * Sprava konca behu v cieli
   */
  private manageEnd(): void {

    if (this.framesToWaitForEndState > 0) {

      this.framesToWaitForEndState -= 1
      if (this.framesToWaitForEndState === 0) {

        this.setEndState()

      }

    }

  }

  /**
   * Aktualizovanie animacii hraca
   * @param delta - Delta
   */
  public updateAnimations(delta: number): void {

    this.animationsManager.update(delta)
    this.bicycle.update(delta)
    this.helperMan.update(delta)

  }

  /**
   * Vypocet attrStrength
   * @returns attrStrength - number
   */
  public getAthleteAttributeStrength(): number {

    const totalStrength = playersManager.getPlayerById(this.uuid)?.attribute.total || 0

    let attrStrength = totalStrength / 4000 + 0.75
    if (totalStrength >= 1000) {

      attrStrength = (totalStrength - 1000) / 5000 + 1

    }

    return attrStrength

  }

  /**
   * Spravanie pri dosiahnuti ciela
   * @param actualPosition - Aktualna pozicia v metroch
   * @param lastPosition - Posledna pozicia v metroch
   * @param finishPosition - Pozicia ciela v metroch
   */
  public finishReached(actualPosition: number, lastPosition: number, finishPosition: number): void {

    audioHelper.afterAthletePassedFinish(this.playable)

    this.isEnd = true
    const finishDist = actualPosition - finishPosition
    const lastFrameDist = actualPosition - lastPosition

    const provisionalTime = timeManager.getGameTimeWithPenaltyInSeconds(false)
    const offsetTime = (finishDist / lastFrameDist) / 30
    // this.finalTime = Math.ceil((provisionalTime - offsetTime) * 100) / 100
    this.finalTime = provisionalTime - offsetTime
    this.finalTime = Math.ceil(this.finalTime * 1000) / 1000
    endManager.setPotentialWinnerInFinish(this.uuid, this.finalTime)

    this.updateFramesAfterStart = false

    console.log(
      `urcovanie presneho casu: ${this.uuid}`,
      `finishDist = ${finishDist}`,
      `lastFrameDist = ${lastFrameDist}`,
      `provisionalTime = ${provisionalTime}`,
      `offsetTime = ${offsetTime}`,
      `finalTime = ${this.finalTime}`
    )
    this.framesToWaitForEndState = 1

    // mimo dennej ligy davame vsetkym superom, co este nepresli cielom po hracovi DNF, aby sa dobre vyhodnocovalo
    if (!modes.isDailyLeague() && this.playable) window.dispatchEvent(new CustomEvent(CustomGameEvents.playerFinished))

  }

  /**
   * Nastavenie stavu konca
   */
  private setEndState(): void {

    this.setState(PlayerStates.end)

    const resultEmotionWinner = endManager.isWinnerInFinish(this.uuid)
    if (resultEmotionWinner) {

      // davame animaciu happy
      this.emotionFinish = EmotionTypesFinish.winner

    }
    if (this.playable && modes.isTrainingMode() && this.isTraining3Stars()) {

      this.emotionFinish = EmotionTypesFinish.winner

    }

  }
  /**
   * Vratenie ci su 3 hviezdy
   * @returns ci su 3 hviezdy
   */
  private isTraining3Stars(): boolean {

    const tasks = trainingManager.getTrainingTasks()
    const sum = tasks.reduce((prev, current) => prev + current.value, 0)
    const average = sum / tasks.length

    // ak 3 hviezdy
    return average > 0.9

  }

  /**
   * reset atleta
   */
  public reset(): void {

    this.state = PlayerStates.prepare
    this.activeUpdatingMovementAnimations = false
    this.lastAthletePosition.set(0, 0, 0)
    this.emotionFinish = EmotionTypesFinish.looser
    this.framesToWaitForEndState = 0
    this.updateFramesAfterStart = false
    this.framesAfterStart = 0
    this.finalTime = minigameConfig.dnfValue
    this.last200m = false
    this.reactionDistance = 0
    this.provisionalPosition = 0
    this.positionOnFinish = 0
    this.isEnd = false

    this.speedBarManager.reset()
    this.speedManager.reset()
    this.animationsManager.resetAll()
    this.worldEnvLinesManager.reset()
    this.athleteAnimationManager.reset()
    this.bicycle.reset()

    const position = gameConfig.start.position.clone()
    position.y += gameConfig.start.yOffset * this.startingOrder
    position.z -= gameConfig.start.zOffset * this.startingOrder
    this.athleteObject.position.set(position.x, position.y, position.z)
    this.helperMan.setVisibility(true)
    this.setVisibilityAthlete(true)

  }

}
