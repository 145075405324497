<template>
  <div
    v-if="gamePhaseState.showBar"
    class="info-key-positioner"
  >
    <tutorial-keyboard-keys
      v-if="showSideArrows"
      type="arrow-keys"
      :text="$t('trackChange')"
      is-scaled
      class="keyboard-side-keys"
    >
      <section
        class="tutorial-keyboard-keys-wrapper"
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="left"
          letter="a"
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="right"
          letter="d"
        />
      </section>
    </tutorial-keyboard-keys>
    <tutorial-keyboard-keys
      v-if="showSpace"
      type="space"
      :text="$t('tempo')"
      class="keyboard-space"
      is-scaled
    >
      <tutorial-keyboard-key
        type="space"
      />
    </tutorial-keyboard-keys>
  </div>
</template>

<script lang="ts">
/* eslint-disable max-len */
import {
  TutorialKeyboardKeys,
  TutorialKeyboardKey
} from '@powerplay/core-minigames-ui-ssm'

import { defineComponent } from 'vue'
import {
  gamePhaseState,
  inputsState
} from '@/stores'

export default defineComponent({
  name: 'TutorialKeys',
  components: {
    TutorialKeyboardKeys,
    TutorialKeyboardKey
  },
  data() {

    return {
      gamePhaseState: gamePhaseState()
    }

  },
  computed: {
    showSpace() {

      return inputsState().isVisible && inputsState().showTempo

    },
    showSideArrows() {

      return inputsState().isVisible && inputsState().showMovement

    }
  }
})
</script>

<style lang="less">
    .info-key-positioner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;
        .keyboard-side-keys {
            transform-origin: 0 100%;
            position: absolute;
            left: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                height: 100px;
                width: 210px;
            }
        }

        .keyboard-space {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;
        }

        .mouse-move {
            background-image: url('https://appspowerplaymanager.vshcdn.net/images/winter-sports/minigame/core-ui/tutorial/ICO-MOUSE-MOVE.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 126px;
            height: 132px;
        }
        .mouse-click {
            background-image: url('https://appspowerplaymanager.vshcdn.net/images/winter-sports/minigame/core-ui/tutorial/ICO-MOUSE-CLICK.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin: 20px 22px;
            width: 70px;
            height: 90px;
        }

        .tutorial-keyboard-keys.tutorial-keyboard-keys-ssm {
          .tutorial-keyboard-header {
            padding: 0 50px;
            .shrinking-text {
              padding: 0;
            }
          }
        }
    }
</style>
