<template>
  <div
    :class="isMobile() ? 'positioner' : 'nomobile-positioner'"
  >
    <arrow-animation
      v-if="glow"
      position="right"
      :style="{
        right: 0,
        transformOrigin: 'left top',
        position: 'absolute',
        width: '350px',
        height: '330px',
        pointerEvents: 'none',
        transform: `scale(${scaleCoef}) translate(0px, 0px)`,
      }
      "
    />
    <div
      class="keirin-bars-wrapper"
      :style="{transform: transformCoef, transformOrigin: 'left top'}"
    >
      <div class="keirin-bars-bars-wrapper">
        <speed-bar-v-2
          :is-scaled="false"
          :fill-size="speedBarState.speedPower"
          :mark="speedBarState.mark"
          show-mark
          :color-override="speedBarState.speedColor"
          :fill-size-top="100 - speedBarState.speedBarMax"
          :class="{'speed-bar-mobile' : isMobile()}"
          :glow="glow"
        />
        <div class="right-side-wrapper">
          <div class="texts-wrapper">
            <div
              class="kmh"
              :class="{'sprinting' : speedBarState.sprintBarFilling < 0}"
            >
              {{ (speedBarState.speed * 3.6).toFixed(0) }}km/h <span
                v-if="speedBarState.sprintBarFilling < 0"
                class="ico-sprint"
              />
            </div>
            <div
              class="slipstream"
              :style="{opacity: speedBarState.isSlipStream ? 1 : 0}"
            >
              {{ $t('utSlipstream') }}
            </div>
          </div>
          <div
            class="sprint-bar-wrapper"
          >
            <speed-bar-v-2
              :is-scaled="false"
              :fill-size="Math.round(speedBarState.sprintBarValue)"
              :class="{'speed-bar-mobile' : isMobile()}"
              color-override="teal"
              :glow="glow"
            />
            <div class="arrows-wrapper">
              <div class="arrows-up">
                <div
                  v-show="speedBarState.sprintBarFilling > 0"
                  class="arrow-up"
                />
                <div
                  v-show="speedBarState.sprintBarFilling > 1"
                  class="arrow-up"
                />
              </div>
              <div class="arrows-down">
                <div
                  v-show="speedBarState.sprintBarFilling < 0"
                  class="arrow-down"
                />
                <div
                  v-show="speedBarState.sprintBarFilling < -1"
                  class="arrow-down"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  WindowAspect,
  SpeedBarV2,
  ArrowAnimation,
} from '@powerplay/core-minigames-ui-ssm'

import { defineComponent } from 'vue'
import { speedBarState } from '@/stores'

export default defineComponent({
  components: {
    SpeedBarV2,
    ArrowAnimation
  },
  mixins: [WindowAspect],
  props: {
    isLeft: {
      type: Boolean,
      default: false
    },
    isScaled: {
      type: Boolean,
      default: true
    },
    glow: {
      type: Boolean,
      default: false
    }
  },
  data() {

    return {
      speedBarState: speedBarState()
    }

  },
  computed: {
    transformCoef() {

      return `scale(${this.scaleCoef})`

    },
  },
})

/* eslint-disable max-len */
</script>

<style scoped lang="less">
.positioner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    pointer-events: none;
    margin-top: 50px;
}

.nomobile-positioner {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 350px;

    .power-start-bar {
        transform-origin: center bottom;
    }
}


.keirin-bars-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  transform-origin: left top;
  height: 330px;

  .keirin-bars-bars-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 15px;
    width: 360px;
    height: 271px;

    .right-side-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 271px;
      gap: 10px;

      .texts-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .slipstream {
          width: 220px;
          height: 37px;
          background: linear-gradient(90deg, rgba(0,197,173,0.8) 0%, rgba(0,197,173,0.5) 60%, rgba(0,197,173,0) 100%);

          text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.71);
          font-family: TTLakes;
          font-size: 24px;
          font-weight: 600;
          font-stretch: condensed;
          color: #fff;
          text-transform: uppercase;

          padding: 0 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .kmh {
          width: 220px;
          height: 46px;
          text-shadow: 0px 2px 2px #000;
          font-family: TTLakes;
          font-size: 36px;
          font-weight: 600;
          font-stretch: condensed;
          color: #fff;
          background: linear-gradient(90deg, rgba(49,50,95,0.8) 0%, rgba(49,50,95,0.5) 60%, rgba(49,50,95,0) 100%);


          padding: 0 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.sprinting {
            background-image: linear-gradient(90deg, rgba(155, 40, 148, 0.8), rgba(155, 40, 148, 0.6), rgba(49, 50, 95, 0.8));
          }
          .ico-sprint {
            background-image: url('https://appspowerplaymanager.vshcdn.net/images/minigame/core-ui/icons/ico-sprint.png');
            width: 47px;
            height: 40px;
          }
        }
      }

      .sprint-bar-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        transform: scale(0.63);
        transform-origin: top left;
        height: 271px;

        .arrows-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;

          .arrows-up {
            .arrow-up {
              transform-origin: left top;
              width: 32px;
              height: 30px;
              background-image: url('https://appspowerplaymanager.vshcdn.net/images/minigame/core-ui/icons/arrow-up.png');
              background-size: contain;
              margin: 0 15px;

            }
          }
          .arrows-down {
            .arrow-down {
              transform-origin: left bottom;
              width: 32px;
              height: 30px;
              background-image: url('https://appspowerplaymanager.vshcdn.net/images/minigame/core-ui/icons/arrow-down.png');
              background-size: contain;
              margin: 0 15px;

            }
          }
        }
      }
    }
  }
}
</style>
