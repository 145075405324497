<template>
  <section
    v-if="isMobile() && (tutorialState.buttons.showJoystick || tutorialState.buttons.showTempo)"
    class="tutorial-buttons-positioner"
  >
    <section
      v-if="!gameSettingsState.isLeft"
      class="flex justify-between positioning"
    >
      <arrow-animation
        v-if="tutorialState.buttons.showJoystick"
        position="bottom"
        style="left:0; bottom: 0%; transform-origin: left bottom;
         position: absolute; width: 580px; height: 472px; pointer-events:none;"
      />
      <movement-control
        v-if="tutorialState.buttons.showJoystick"
        glow
        :style="[
          {transform: `scale(${1})`, width: '100%'},
          shootPosition,
        ]"
        class="bottom-left"
      />
      <section />
      <section
        :style="{position: 'absolute', right: '0', bottom: '0'}"
      >
        <div
          v-if="tutorialState.buttons.showTempo"
          class="bottom-right"
          :style="{transform: `scale(${scaleCoef})`}"
        >
          <arrow-animation
            :is-scaled="false"
            position="bottom"
            style="left:0; bottom: 0%; transform-origin: left bottom;
            position: absolute; width: 580px; height: 472px; pointer-events:none;"
          />
          <action-button glow />
        </div>
      </section>
    </section>
    <section
      v-else
      class="flex justify-between positioning"
    >
      <section
        :style="{position: 'absolute', left: '0', bottom: '0'}"
      >
        <div
          v-if="tutorialState.buttons.showTempo"
          class="bottom-left"
          :style="[
            {transform: `scale(${scaleCoef})`},
            {opacity: tutorialState.buttons.showTempo ? 1 : 0}
          ]"
        >
          <arrow-animation
            :is-scaled="false"
            position="bottom"
            style="left:0; bottom: 0%; transform-origin: left bottom; position: absolute;
            width: 580px; height: 472px; pointer-events:none;"
          />
          <action-button glow />
        </div>
      </section>
      <section />
      <arrow-animation
        v-if="tutorialState.buttons.showJoystick"
        position="bottom"
        style="right:0; bottom: 0%; transform-origin: right bottom;
        position: absolute; width: 580px; height: 472px; pointer-events:none;"
      />
      <movement-control
        v-if="tutorialState.buttons.showJoystick"
        glow
        :style="[
          {transform: `scale(${1})`, width: '100%'},
          shootPosition
        ]"
        class="bottom-right"
      />
    </section>
  </section>
</template>

<script lang="ts">
import MovementControl from '../Inputs/MovementControl.vue'
import ActionButton from '../Inputs/ActionButton.vue'
import {
  WindowAspect,
  ArrowAnimation,
  gameSettingsState
} from '@powerplay/core-minigames-ui-ssm'
import { defineComponent } from 'vue'
import { tutorialState } from '@/stores'

export default defineComponent({
  name: 'MobileInputs',
  components: {
    MovementControl,
    ActionButton,
    ArrowAnimation
  },
  mixins: [WindowAspect],
  data() {

    return {
      tutorialState: tutorialState(),
      gameSettingsState: gameSettingsState()
    }

  },
  computed: {
    movementControlPosition() {

      return {
        position: 'absolute',
        height: '100%'
      }

    },
    shootPosition() {

      let left = `calc(50% - (580px * ${this.scaleCoef} / 2))`
      let right = ''
      if (!gameSettingsState().isLeft) {

        left = ''
        right = `calc(50% - (580px * ${this.scaleCoef} / 2))`

      }

      return {
        position: 'absolute',
        left,
        right,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: `calc(50% - (580px * ${this.scaleCoef} / 2))`,
        height: '100%'
      }

    }
  }
})
</script>

<style lang="less" scoped>
.tutorial-buttons-positioner {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    pointer-events: auto;
    transform: none;
  }

  .positioning {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .flex {
    display: flex;
  }

  .justify-between {
    justify-content: space-between;
  }

  .relative {
    position: relative;
  }

  .pointer-events-auto {
    pointer-events: auto;
  }

  .buttons-position {
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    height: 200px;
  }
  .bottom-right {
    transform-origin: bottom right;
    display: flex;
    justify-content: flex-end;
  }
  .bottom-left {
    transform-origin: bottom left;
    display: flex;
    justify-content: flex-start;
  }

</style>
