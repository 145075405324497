import {
  tutorialManager,
  TutorialSectionType,
  game,
  modes,
  MobileDetector,
  gsap,
  TutorialMessageColors,
  fpsManager,
  requestManager
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../../phases/DisciplinePhasesManager'
import {
  SectionNames,
  Sides,
  TutorialEventType,
  TutorialObjectiveIds
} from '../../types'
import { tutorialObjectives } from './TutorialObjectives'
import { tutorialUIChange } from './TutorialUIChange'
import { player } from '@/app/entities/athlete/player'
import {
  actionButtonState,
  blurState,
  inputsState,
  tutorialState
} from '@/stores'
import {
  gameSettingsState,
  tutorialCoreState
} from '@powerplay/core-minigames-ui-ssm'

/**
 *  Tutorial tasky ktore maju aj logiku v sebe na ovladanie tej ktorej udalosti
 */
export class TutorialFlow {

  private activeEventType = TutorialEventType.awaitingEvent

  /** true ak uz bola uspesna prva zmnena drahy */
  public firstTurnTriggered = false

  /** true ak uz bola uspesna prva zmnena drahy */
  public firstTurnFinished = false

  /** tween na cakanie zmeny drahy hracom */
  public changePathTween?: gsap.core.Tween

  /** pocitame framy ak nesprintuje */
  public notSprintingFrames = 0

  /** pocitame framy ak je v slipStreame */
  public slipStreamFrames = 0

  /** ci uz bol trigger slipstreamsuccess */
  public slipStreamSuccess = false

  /** rychlost na splnenie tasku */
  public requiredSpeed = 0

  /** ci uz bol event requiredSpeed */
  public requiredSpeedEventTriggered = false

  /** ci uz presli startovacou ciarou */
  private started = false

  public setObjectivesInit(): void {

    const objectives = [
      {
        id: TutorialObjectiveIds.slipStream as string,
        passed: false,
        failed: false,
        name: `tutorialTask${requestManager.disciplineID}-1`
      },
      {
        id: TutorialObjectiveIds.changePath as string,
        passed: false,
        failed: false,
        name: `tutorialTask${requestManager.disciplineID}-2`
      },
      {
        id: TutorialObjectiveIds.requiredSpeed as string,
        passed: false,
        failed: false,
        name: `tutorialTask${requestManager.disciplineID}-3`
      },
      {
        id: TutorialObjectiveIds.finish as string,
        passed: false,
        failed: false,
        name: `tutorialTask${requestManager.disciplineID}-4`
      }
    ]
    tutorialObjectives.setObjectives(objectives)
    this.requiredSpeed = Math.floor((
      player.speedManager.minSpeed + player.speedManager.cruiseSpeedConst + player.speedManager.sprintSpeedConst
    ) * 3.6) - 1

  }

  /**
   * Inicializacia
   */
  public init(): void {

    const tutorialSections = [
      {
        name: SectionNames.handCheck,
        id: 0,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          actionButtonState().isStart = true
          blurState().isActive = false
          this.setObjectivesInit()

        }
      },
      {
        name: SectionNames.startSection,
        id: 1,
        type: TutorialSectionType.storyInput
      },
      {
        name: SectionNames.startSectionSecond,
        id: 2,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          this.resetTypeWrite()

        },
        sectionLogicFinish: () => {

          if (game.paused) game.resumeGame()

          tutorialUIChange.setMessage(false, '')
          tutorialUIChange.setAnne(false)
          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.startSectionThird,
        id: 3,
        type: TutorialSectionType.gameEvent,
        sectionLogicIntro: () => {

          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.endSection,
        id: 6,
        type: TutorialSectionType.gameEvent
      }
    ]

    actionButtonState().isStart = false

    tutorialManager.setTutorialSections(tutorialSections)

    inputsState().$patch({
      showTempo: false,
      showMovement: false
    })

    const firstTutorialStrings = ['chooseFirstDisciplineReturnFromMinigame', 'chooseFirstDisciplineContinue']
    if (
      (MobileDetector.isMobile() && firstTutorialStrings.includes(requestManager.TUTORIAL_ID ?? '')) ||
      disciplinePhasesManager.attempt > 1
    ) return
    tutorialManager.setActualSectionId(1)
    actionButtonState().isStart = true

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    // this.checkInput()
    tutorialUIChange.update()
    tutorialObjectives.update()
    this.checkNotSprintingTime()

    if (this.firstTurnFinished) return
    if (this.checkMetersBehind() > 10) {

      if (this.slipStreamSuccess) return
      this.eventActionTrigger(TutorialEventType.tooFar10)

    } else if (this.checkMetersBehind() > 5) {

      // realtimeinfo
      console.log('realtimeInfo 5m')
      const text = MobileDetector.isMobile() ?
        `tutorialInfoBox${requestManager.disciplineID}-1m` :
        `tutorialInfoBox${requestManager.disciplineID}-1`

      tutorialState().$patch({
        instructionBoxText: text,
        instructionBoxIcon: 1,
        showInstructionBox: true
      })

    } else {

      tutorialState().showInstructionBox = false

    }

  }

  /**
   * Kontrola inputov
   */
  public checkInput(): void {

    if (TutorialSectionType.gameEvent === tutorialManager.getActualSectionType()) {

      this.eventActionPressed()

    }

  }

  /**
   * Vyhodnocovanie inputov na menenie drahy
   * @param side - side
   */
  public changePathInput(side: Sides): void {

    if (!modes.isTutorial()) return

    if (
      [
        TutorialEventType.changePathTimeout,
        TutorialEventType.slipStreamSuccess,
      ].includes(this.activeEventType)) {

      if (side === Sides.LEFT) return
      player.changePath(side)
      this.eventActionPressed()
      tutorialManager.inputsBlocked = false

    }

    tutorialState().buttons = {
      showTempo: false,
      showJoystick: false,
    }

  }

  public eventActionTrigger(eventType: TutorialEventType): void {

    if (!modes.isTutorial()) return
    this.activeEventType = eventType

    if (eventType === TutorialEventType.start) {

      if (this.started) {

        this.activeEventType = TutorialEventType.awaitingEvent
        return

      }

      tutorialState().buttons = {
        showTempo: true,
        showJoystick: false,
      }
      inputsState().$patch({
        showTempo: true,
        isVisible: true
      })

      tutorialManager.blockInputsManually(1000)
      let isLeft = gameSettingsState().isLeft
      if (!MobileDetector.isMobile()) isLeft = false
      tutorialUIChange.setMessage(
        true,
        `tutorialText${requestManager.disciplineID}-3`,
        undefined,
        undefined,
        !MobileDetector.isMobile()
      )
      tutorialUIChange.setAnne(true, isLeft)
      this.resetTypeWrite()
      game.pauseGame()
      this.started = true
      return

    }

    if (!this.started) {

      this.activeEventType = TutorialEventType.awaitingEvent
      return

    }
    if (eventType === TutorialEventType.tooFar10) {

      if (this.firstTurnTriggered) {

        this.activeEventType = TutorialEventType.awaitingEvent
        return

      }
      tutorialManager.blockInputsManually(1000)
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-4`)
      tutorialUIChange.setAnne(true, true)
      this.resetTypeWrite()
      game.pauseGame()
      return

    }

    if (eventType === TutorialEventType.slipStreamSuccess) {

      if (this.slipStreamSuccess) {

        this.activeEventType = TutorialEventType.awaitingEvent
        return

      }
      tutorialManager.blockInputsManually(1000)
      let isLeft = gameSettingsState().isLeft
      if (!MobileDetector.isMobile()) isLeft = false
      tutorialUIChange.setMessage(
        true,
        `tutorialText${requestManager.disciplineID}-5`,
        undefined,
        undefined,
        !MobileDetector.isMobile()
      )
      tutorialUIChange.setAnne(true, !isLeft)
      this.resetTypeWrite()
      this.slipStreamSuccess = true

      tutorialState().buttons = {
        showTempo: false,
        showJoystick: true,
      }
      inputsState().$patch({
        showMovement: true,
        isVisible: true
      })

      this.changePathTween = gsap.to({}, {
        onComplete: () => {

          this.eventActionTrigger(TutorialEventType.changePathTimeout)

        },
        duration: 5
      })
      game.pauseGame()
      return

    }
    if (eventType === TutorialEventType.changePathTimeout) {

      if (this.firstTurnTriggered) {

        this.activeEventType = TutorialEventType.awaitingEvent
        return

      }

      this.firstTurnTriggered = true
      this.resetTypeWrite()
      game.pauseGame()
      tutorialState().buttons = {
        showTempo: false,
        showJoystick: true,
      }
      const yellowTextSpecial = 'rightArrow'
      tutorialManager.blockInputsManually(0)
      let isLeft = gameSettingsState().isLeft
      if (!MobileDetector.isMobile()) isLeft = false
      tutorialUIChange.setMessage(
        true,
        `tutorialText${requestManager.disciplineID}-6`,
        undefined,
        yellowTextSpecial,
        !MobileDetector.isMobile()
      )
      tutorialUIChange.setAnne(true, isLeft)

    }
    if (eventType === TutorialEventType.changePathDone) {

      if (this.firstTurnFinished) {

        this.activeEventType = TutorialEventType.awaitingEvent
        return

      }
      this.firstTurnTriggered = true
      this.resetTypeWrite()
      game.pauseGame()
      tutorialManager.blockInputsManually(1000)
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-7`)
      tutorialUIChange.setAnne(true, true)
      tutorialObjectives.passObjective(TutorialObjectiveIds.changePath)

      tutorialState().showBarSpeed = true

    }
    if (eventType === TutorialEventType.requiredSpeed) {

      tutorialManager.blockInputsManually(1000)
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-8`)
      tutorialUIChange.setAnne(true, true)
      this.resetTypeWrite()
      game.pauseGame()
      return

    }
    if (eventType === TutorialEventType.last200m) {

      tutorialManager.blockInputsManually(1000)
      tutorialUIChange.setMessage(
        true,
        `tutorialText${requestManager.disciplineID}-9`,
        undefined,
        undefined,
        !MobileDetector.isMobile()
      )
      tutorialUIChange.setAnne(true, true)
      this.resetTypeWrite()
      game.pauseGame()
      return

    }
    if (eventType === TutorialEventType.finish) {

      this.resetTypeWrite()
      game.pauseGame()
      let text = `tutorialText${requestManager.disciplineID}-11`
      let color = TutorialMessageColors.green
      if (
        !tutorialObjectives.checkIfObjectivePassed(tutorialObjectives.
          getObjectiveById(TutorialObjectiveIds.requiredSpeed))
      ) {

        text = `tutorialText${requestManager.disciplineID}-10`
        color = TutorialMessageColors.red

      }
      tutorialManager.blockInputsManually(1000)
      tutorialUIChange.setMessage(true, text, color, undefined, !MobileDetector.isMobile())
      tutorialUIChange.setAnne(true)

    }

  }

  /** Reset typewrite */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(false)
    tutorialCoreState().typeWriter = false

  }

  public eventActionPressed(): void {

    if (game.paused) game.resumeGame()

    tutorialUIChange.setMessage(false, '')
    tutorialUIChange.setAnne(false)

    if (this.activeEventType === TutorialEventType.tooFar10) {

      this.slipStreamFrames = 0

      tutorialState().slipStreamSeconds = Math.floor(this.slipStreamFrames / fpsManager.fpsLimit)
      disciplinePhasesManager.resetAttempt()

    }
    if (this.activeEventType === TutorialEventType.changePathDone) {

      this.firstTurnFinished = true

    }
    if (
      [
        TutorialEventType.changePathDone,
        TutorialEventType.changePathSuccess,
        TutorialEventType.changePathTimeout,
        TutorialEventType.slipStreamSuccess,
        TutorialEventType.start
      ].includes(this.activeEventType)
    ) {

      tutorialState().$patch({
        buttons: {
          showTempo: false,
          showJoystick: false
        },
        showBarSpeed: false
      })
      tutorialManager.inputsBlocked = false

    }
    if (this.activeEventType === TutorialEventType.finish) {

      game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)
      tutorialManager.nextSection()
      tutorialManager.blockInputsManually(0)

    }

    this.activeEventType = TutorialEventType.awaitingEvent

  }

  /**
   * Hrac vosiel do slipStreamu
   */
  public inSlipStream(): void {

    if (!modes.isTutorial() || this.slipStreamSuccess) return

    this.slipStreamFrames += 1
    tutorialState().slipStreamSeconds = Math.floor(this.slipStreamFrames / fpsManager.fpsLimit)
    if (this.slipStreamFrames >= 10 * fpsManager.fpsLimit) {

      tutorialObjectives.passObjective(TutorialObjectiveIds.slipStream as string)
      this.eventActionTrigger(TutorialEventType.slipStreamSuccess)

    }

  }

  /**
   * vyhodnocovanie rychlosti
   */
  public checkRequiredSpeed(speed: number): void {

    if (!modes.isTutorial() || !this.firstTurnFinished) return
    if (
      speed >= this.requiredSpeed &&
      !this.requiredSpeedEventTriggered
    ) {

      this.requiredSpeedEventTriggered = true
      tutorialObjectives.passObjective(TutorialObjectiveIds.requiredSpeed)
      this.eventActionTrigger(TutorialEventType.requiredSpeed)

    }

  }

  /**
   * Kontrolovanie ako daleko je hrac za inym cyklistom
   */
  private checkMetersBehind(): number {

    if (player.athleteOfSlipStream === undefined) return -1

    return player.reactionDistance - player.athleteOfSlipStream.reactionDistance

  }

  /**
   * Kontrolovanie ako dlho hrac nesprintuje
   */
  private checkNotSprintingTime(): void {

    if (!this.firstTurnFinished) return

    if (player.isSprinting) {

      this.notSprintingFrames = 0
      return

    }
    this.notSprintingFrames += 1
    if (this.notSprintingFrames >= 3 * fpsManager.fpsLimit) {

      // realtimeinfo
      console.log('realtimeInfo sprinting')
      const text = MobileDetector.isMobile() ?
        `tutorialInfoBox${requestManager.disciplineID}-2m` :
        `tutorialInfoBox${requestManager.disciplineID}-2`

      tutorialState().$patch({
        instructionBoxText: text,
        instructionBoxIcon: 1,
        showInstructionBox: true
      })

    }

  }

}

export const tutorialFlow = new TutorialFlow()
