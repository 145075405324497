import type { BlueBoxTextType } from '@/app/types'
import { defineStore } from 'pinia'

export interface UiState {
  showTimeKeeper: boolean,
  showFinishTopBox: boolean,
  showTrainingLayout: boolean,
  isTraining: boolean,
  blueBoxTextType: BlueBoxTextType
}

const initialState = (): UiState => ({
  showTimeKeeper: false,
  showFinishTopBox: false,
  showTrainingLayout: false,
  isTraining: false,
  blueBoxTextType: -1
})

export const uiState = defineStore('uiState', {
  state: initialState
})
