import { ArrowTypes } from '@/app/types'
import { defineStore } from 'pinia'


export interface ArrowDistancesState {
  leftType: string,
  leftValue: number,
  middleType: string,
  middleValue: number,
  rightType: string,
  rightValue: number,
  limitToShow: number,
  showArrows: boolean
}

const initialState = (): ArrowDistancesState => ({
  leftType: ArrowTypes.none,
  leftValue: 0,
  middleType: ArrowTypes.none,
  middleValue: 0,
  rightType: ArrowTypes.none,
  rightValue: 0,
  limitToShow: 0,
  showArrows: false
})

export const arrowDistancesState = defineStore('arrowDistancesState', {
  state: initialState
})
