import { defineStore } from 'pinia'

export interface TextMessageState {
  showFinalLap: boolean,
  showCountdown: boolean,
  showType: number,
  showTogo: boolean
}

const initialState = (): TextMessageState => ({
  showFinalLap: false,
  showCountdown: false,
  showType: 0,
  showTogo: false
})

export const textMessageState = defineStore('textMessageState', {
  state: initialState
})
