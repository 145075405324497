import { defineStore } from 'pinia'

export interface InputsState {
  disabled: boolean
  isVisible: boolean
  actionDisabled: boolean,
  isForceJoystick: boolean,
  exitPressed: boolean,
  showTempo: boolean,
  showMovement: boolean
}

const initialState = (): InputsState => ({
  disabled: true,
  isVisible: false,
  actionDisabled: false,
  isForceJoystick: false,
  exitPressed: false,
  showTempo: true,
  showMovement: true
})

export const inputsState = defineStore('inputsState', {
  state: initialState
})