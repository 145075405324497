<template>
  <div
    class="training-layout"
  >
    <training-left-top-box
      v-if="trainingState.showLeftBoxes"
      :text="$t('highScore')"
      :points="highScore"
      :tasks="trainingState.tasks"
      :max-t-p-length="maxTPLength"
    />
  </div>
  <training-blue-box
    v-if="trainingState.showNewHighScore"
    :points="String(trainingState.newHighScore)"
    :text="$t('newHighScore')"
    :style="{width: 100 / scaleCoef + '%'}"
  />
</template>

<script lang="ts">
import {
  TrainingBlueBox,
  TrainingLeftTopBox,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import { trainingManager } from '@powerplay/core-minigames'

import { defineComponent } from 'vue'
import { trainingState } from '@/stores'

export default defineComponent({
  components: {
    TrainingLeftTopBox,
    TrainingBlueBox
  },
  mixins: [WindowAspect],
  data() {

    return {
      trainingState: trainingState()
    }

  },
  computed: {
    highScore(): string {

      return String(trainingManager.bestScore)

    },
    maxTPLength() {

      return trainingManager.getTpPerTask().toString().length

    }
  },
})

</script>

<style lang="less" scoped>
.training-layout {
    position: absolute;
    left: 1%;
    top: 30px;
    width: 100%;
    height: 100%;
}
.training-blue-box {
    position: fixed;
}
</style>
