import { defineStore } from 'pinia'

export interface SpeedBarState {
  speedPower: number,
  mark: number,
  speedColor: string,
  speedBarMax: number,
  sprintBarValue: number,
  isSlipStream: boolean,
  sprintBarFilling: number,
  speed: number
}

const initialState = (): SpeedBarState => ({
  speedPower: 0,
  mark: 80,
  speedColor: '',
  speedBarMax: 100,
  sprintBarValue: 100,
  isSlipStream: false,
  sprintBarFilling: 0,
  speed: 0
})

export const speedBarState = defineStore('speedBarState', {
  state: initialState
})
