<template>
  <time-keeper
    v-if="uiState.showTimeKeeper"
    class="time-keeper"
    :text1="timeState.time"
    :is-scaled="false"
  />
</template>

<script lang="ts">
import { TimeKeeper } from '@powerplay/core-minigames-ui-ssm'

import { defineComponent } from 'vue'
import {
  timeState,
  uiState
} from '@/stores'

export default defineComponent({
  name: 'TimeKeeperComponent',
  components: {
    TimeKeeper
  },
  data() {

    return {
      timeState: timeState(),
      uiState: uiState()
    }

  }

})

</script>

<style lang="less">
.component-item {

    .time-keeper {
        position: initial;
        height: auto;
    }
}
</style>
