import {
  pathAssets,
  pathAssetsGlobal
} from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { ModelsNames } from '../types'

// Hlavny priecinok s modelmi
const modelsDirKeirin = `${pathAssets}/models/`
const modelsDirGlobal = `${pathAssetsGlobal}/models/`

/**
 * Konfig pre modely
 */
export const modelsConfig: LoaderDataTypes = {
  [ModelsNames.athlete]: {
    ext: 'glb',
    genderActive: true,
    dir: modelsDirKeirin,
    version: 2406242,
    femaleVersion: 240624,
    mainMeshNames: [ModelsNames.athlete],
    opponentCheck: true
  },
  [ModelsNames.env]: {
    ext: 'glb',
    version: 240311,
    dir: modelsDirKeirin
  },
  [ModelsNames.trackLines]: {
    ext: 'glb',
    version: 240318,
    dir: modelsDirKeirin
  },
  [ModelsNames.skyboxBuildings]: {
    ext: 'glb',
    version: 241016,
    dir: modelsDirGlobal
  },
  [ModelsNames.helperMan]: {
    ext: 'glb',
    dir: modelsDirKeirin
  },
  [ModelsNames.finish]: {
    ext: 'glb',
    version: 240618,
    dir: modelsDirKeirin
  },
  // [ModelsNames.cameraBoxes]: {
  //   ext: 'glb',
  //   version: 240327,
  //   dir: modelsDirKeirin,
  // }

}
