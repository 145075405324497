import { player } from '../../entities/athlete/player'
import { type DisciplinePhaseManager } from '../../types'
import {
  timeManager,
  playersManager,
  fpsManager,
  modes,
  corePhasesManager,
  gsap,
  TimesTypes,
  trainingManager
} from '@powerplay/core-minigames'
import { endManager } from '@/app/EndManager'
import { opponentsManager } from '@/app/entities/athlete/opponent/OpponentsManager'
import { startPhaseStateManager } from '../StartPhase/StartPhaseStateManager'
import {
  emotionMessagesState,
  gamePhaseState,
  gameplayTableState,
  inputsState,
  lapPositionState,
  tableState,
  trainingState,
  uiState
} from '@/stores'
import { waitingState } from '@powerplay/core-minigames-ui-ssm'

/**
 * Trieda fazy pre dojazd v cieli (resp naburanie)
 */
export class FinishPhaseManager implements DisciplinePhaseManager {

  /** Ci su skipovatelne hlasky pri konecnej emocii */
  public emotionMessagesSkippable = false

  /** Freeznutie zaznamenavania superov pri dennej lige, lebo pouzivame fake superov */
  public dailyLeagueSetResultsOpponentsFreeze = false

  /** Ci sa podarilo odoslat */
  public sendSaveResultDone = false

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** tween na ukoncenie fazy po animacii */
  private finishPhaseTween !: gsap.core.Tween

  /** ci faza skoncila */
  private ended = false

  /** Aktualna rychlost pohybu */
  private actualSpeed = 0

  /** tween na zmenu UI stavu */
  private changeUiStateTween!: gsap.core.Tween

  /** kolko po starte mame zobrazit finish top box */
  private SHOW_FINISH_TOP_BOX_SECONDS = 2

  /** Kolko superov dobehlo do ciela */
  public finishedOpponents = 0

  /** tween na emocii */
  private emotionTween?: gsap.core.Tween

  /** tween pre unlock skipu finalnej emocie */
  private tweenToSkipFinalEmotions?: gsap.core.Tween

  /** Ci uz su emocie hotove */
  private emotionsDone = false

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {
    // nic
  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    if (modes.isTutorial()) return

    this.generateFakeOpponentsData()
    fpsManager.pauseCounting()

    this.reset()
    this.preparePhase()

    playersManager.setStandings(3)
    console.log('STANDINGS', playersManager.getStandings())

    console.warn('finish phase started')

    tableState().dataTable = playersManager.getStandings()
    inputsState().isVisible = false
    gamePhaseState().showSmallActionButtons = false

    player.finishAction()

    // this.actualSpeed = player.speedBarManager.getVelocity() / fpsManager.fpsLimit
    console.log(`Finish speed ${this.actualSpeed}`)

    uiState().$patch({
      showTimeKeeper: true,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

    this.changeUiStateTween = gsap.to({}, {
      duration: this.SHOW_FINISH_TOP_BOX_SECONDS,
      onComplete: () => {

        uiState().$patch({
          showTimeKeeper: false,
          showFinishTopBox: (!modes.isTutorial() && !modes.isTrainingMode()),
          showTrainingLayout: modes.isTrainingMode(),
          isTraining: modes.isTrainingMode()
        })
        lapPositionState().showPosition = false
        this.prepareEmotionMessages()

      }
    })

  }

  /**
   * Vratenie final casu atleta podla uuid
   * @param uuid - UUID atleta
   * @returns Final cas
   */
  public getAthleteFinalTimeByUUID(uuid: string): number {

    if (player.uuid === uuid) return player.finalTime

    const index = opponentsManager.getOpponentsIds().findIndex(id => id === uuid)
    return opponentsManager.getOpponents()[index]?.finalTime ?? 0

  }

  /**
   * nastavime data pre top box
   */
  public prepareEmotionMessages(): void {

    if (corePhasesManager.disciplineActualAttempt < corePhasesManager.disciplineAttemptsCount) return

    let withEmotionMessages = false

    // UI pri emocii, ak islo o posledny pokus
    if (modes.isTrainingMode()) {

      // HIGH SCORE - TRENING
      trainingState().$patch({
        showLeftBoxes: false,
        newHighScore: Math.ceil(trainingManager.getNewPotentialHighScore()),
        showNewHighScore: trainingManager.isNewHighScore()
      })

      this.setTimerToSkipFinalEmotions()

    } else {

      // UI veci
      withEmotionMessages = this.showEmotionMessages()

    }

    if (withEmotionMessages) return

    this.emotionTween = gsap.to(
      {},
      {
        duration: 2.5,
        onComplete: () => {

          this.tweenToSkipFinalEmotions?.kill()
          this.afterEmotions()

        }
      }
    )

  }

  /**
   * Spravenie veci po emociach
   */
  public afterEmotions(): void {

    trainingState().showNewHighScore = false
    emotionMessagesState().showMessage = false

    this.emotionsDone = true
    player.athleteAnimationManager.sadAnimationIdleTween?.kill()
    opponentsManager.getOpponents().forEach(o => {

      o.athleteAnimationManager.sadAnimationIdleTween?.kill()

    })

  }

  /**
   * Nastavenie timeru na skip final emocie
   */
  private setTimerToSkipFinalEmotions(): void {

    // dame timer na skip poslednej emocie s hlaskami
    this.tweenToSkipFinalEmotions = gsap.to({}, {
      duration: 1.5,
      onComplete: () => {

        this.emotionMessagesSkippable = true

      }
    })

  }

  /**
   * nastavime emocne message
   * @returns True, ak zobrazujeme emocnu hlasku
   */
  public showEmotionMessages(): boolean {

    playersManager.setStandings(3)
    const timeSeconds = Math.ceil(player.finalTime * 1000) / 1000
    const personalBest = playersManager.getPlayer().personalBest
    const position = playersManager.getPlayerActualPosition(player.uuid)
    const inTop3 = position <= 3 || modes.isTrainingMode()
    const isPersonalBest = modes.isTournament() ? false : timeSeconds <= personalBest

    // ak mame nejaku hlasku/y, tak zobrazime kameru kusok inak
    if (
      ((modes.isDailyLeague() || modes.isTournament()) && !playersManager.isPlayerImproved()) ||
      (!inTop3 && !isPersonalBest)
    ) return false

    startPhaseStateManager.hideAllTextMessages()

    let pbText = ''
    if (isPersonalBest) {

      pbText = timeSeconds < personalBest ? 'newPersonalBest' : 'personalBest'

    }

    emotionMessagesState().$patch({
      showMessage: true,
      firstLineText: inTop3 ? 'congratulations' : '',
      firstLineTextSecond: pbText,
      secondLineText: inTop3 ? position : 0,
      secondLineTextSecond: isPersonalBest ? timeManager.getTimeInFormatFromSeconds(timeSeconds, 3) : '',
    })

    // dame timer na skip poslednej emocie s hlaskami
    this.setTimerToSkipFinalEmotions()

    return true

  }

  /**
   * Kontrola a riesenie inputov
   */
  public handleInputs(): void {

    // skip na emocie
    if (this.emotionMessagesSkippable && this.allOpponentsInFinish()) {

      this.emotionTween?.kill()
      this.emotionMessagesSkippable = false
      this.afterEmotions()

      return

    }

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.checkEnd()

  }

  /**
   * Zistenie, ci su vsetci superi v cieli
   * @returns True, ak su vsetci superi v cieli
   */
  private allOpponentsInFinish(): boolean {

    return this.finishedOpponents === opponentsManager.getOpponentsCount()

  }

  /**
   * Kontrola konca
   */
  private checkEnd(): void {

    // ak je hrac uz v cieli a cakame na protihracov, aby sa uz dalo skipnut
    if (this.allOpponentsInFinish() && this.emotionsDone) {

      this.setFinishPhaseTween()

    }

  }

  /**
   * Zobrazenie finalnej tabulky
   */
  public prepareFinishTable() {

    this.setTournamentTableSettings()
    timeManager.setActive(TimesTypes.game, false)
    // data pre tabulku
    playersManager.setStandings(3)
    console.log('STANDINGS', playersManager.getStandings())

    tableState().dataTable = playersManager.getStandings()

  }

  /**
   * Vygenerovanie casy pre hracov ktori nestihli prejst cielom
   */
  private generateFakeOpponentsData(): void {

    if (!modes.isDailyLeague()) return

    // musime nastavit, aby sa uz ziadne data nezapisovali dalsie
    this.dailyLeagueSetResultsOpponentsFreeze = true

    // kvoli dennej lige musime dat naspat originalnych superov, aby sa zobrazili v konecnej listine
    opponentsManager.setOriginalData()

  }

  /**
   * Nastavenie dat o hracoch
   */
  private setTournamentTableSettings(): void {

    if (!modes.isTournament()) return
    waitingState().isWaiting = true
    tableState().$patch({
      resultText: 'provisionalResults',
      showTable: true,
      activeState: false,
      dataTable: [],
      isStartList: false,
    })

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    this.prepareFinishTable()
    if (this.ended) return
    this.ended = true

    this.emotionMessagesSkippable = false

    if (this.finishPhaseTween) this.finishPhaseTween.kill()
    if (this.changeUiStateTween) this.changeUiStateTween.kill()

    gameplayTableState().showTables = false

    // resetujeme vsetky hlasky, aby nahodou neostali
    startPhaseStateManager.hideTextMessage(1)
    startPhaseStateManager.hideTextMessage(2)

    uiState().$patch({
      showTimeKeeper: false,
      showFinishTopBox: (!modes.isTutorial() && !modes.isTrainingMode()),
      showTrainingLayout: false,
      isTraining: modes.isTrainingMode()
    })

    fpsManager.pauseCounting()
    endManager.sendLogEnd()
    endManager.sendSaveResult()
    opponentsManager.getOpponents().forEach(opponent => {

      opponent.setVisibilityAthlete(false)

    })
    player.setVisibilityAthlete(false)
    console.warn('finish phase ended')
    this.callbackEnd()

  }

  /**
   * sets tween to finish phase
   */
  public setFinishPhaseTween(): void {

    this.finishPhase()

  }

  /**
   * reset fazy
   */
  public reset(): void {

    this.ended = false
    if (this.changeUiStateTween) this.changeUiStateTween.kill()
    this.emotionMessagesSkippable = false
    this.emotionTween?.kill()
    this.emotionsDone = false

  }

}
