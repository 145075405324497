<template>
  <div
    v-if="tutorialState.showBarSpeed"
    class="speed-bar"
  >
    <speed-bar glow />
  </div>
</template>

<script lang="ts">
import SpeedBar from '@/components/Inputs/SpeedBar.vue'

import { defineComponent } from 'vue'
import { tutorialState } from '@/stores'

export default defineComponent({
  name: 'TutorialBars',
  components: {
    SpeedBar
  },
  data() {

    return {
      tutorialState: tutorialState()
    }

  }
})
</script>

<style scoped lang="less">
.speed-bar {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}
</style>
