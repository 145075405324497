import { Sides } from '@/app/types'
import { defineStore } from 'pinia'

export interface BlockingState {
  side: Sides | undefined,
  isVisible: boolean,
  leanOn: number
}

const initialState = (): BlockingState => ({
  side: Sides.LEFT,
  isVisible: false,
  leanOn: 0
})

export const blockingState = defineStore('blockingState', {
  state: initialState
})
