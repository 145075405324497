import { defineStore } from 'pinia'

export interface GamePhaseState {
  showBar: boolean,
  showSmallActionButtons: boolean,
  disabledSmallAction: boolean,
  showUi: boolean,
}

const initialState = (): GamePhaseState => ({
  showBar: false,
  showSmallActionButtons: true,
  disabledSmallAction: false,
  showUi: true,
})

export const gamePhaseState = defineStore('gamePhaseState', {
  state: initialState
})
