import {
  pathAssetsGlobal,
  pathAssets
} from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { TexturesNames } from '../types'

// Hlavny priecinok s texturami
const texturesDirKeirin = `${pathAssets}/textures/`
const texturesDirGlobal = `${pathAssetsGlobal}/textures/`

/**
 * Konfig pre textury
 */
export const texturesConfig: LoaderDataTypes = {
  [TexturesNames.athleteRaceWhiteMan]: {
    ext: 'ktx2',
    version: 231206,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.athleteRaceMulattoMan]: {
    ext: 'ktx2',
    version: 231206,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.athleteRaceBlackMan]: {
    ext: 'ktx2',
    version: 231206,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.athleteRaceWhiteWoman]: {
    ext: 'ktx2',
    version: 231206,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.athleteRaceMulattoWoman]: {
    ext: 'ktx2',
    version: 231206,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.athleteRaceBlackWoman]: {
    ext: 'ktx2',
    version: 231206,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.athleteClothes]: {
    ext: 'ktx2',
    genderActive: true,
    version: 240531,
    femaleVersion: 240531,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent1]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240529,
    femaleVersion: 240529,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent2]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240529,
    femaleVersion: 240529,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent3]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240529,
    femaleVersion: 240529,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent4]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240529,
    femaleVersion: 240529,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent5]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 240529,
    femaleVersion: 240529,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.bicycle]: {
    ext: 'ktx2',
    version: 230921,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.bicycleOpponent1]: {
    ext: 'ktx2',
    opponent: true,
    version: 230921,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.bicycleOpponent2]: {
    ext: 'ktx2',
    opponent: true,
    version: 230921,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.bicycleOpponent3]: {
    ext: 'ktx2',
    opponent: true,
    version: 230921,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.bicycleOpponent4]: {
    ext: 'ktx2',
    opponent: true,
    version: 230921,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.bicycleOpponent5]: {
    ext: 'ktx2',
    opponent: true,
    version: 230921,
    dir: `${texturesDirKeirin}/athlete/`
  },
  [TexturesNames.audience]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.colorGradient]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.colorGradientVshosting]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.concrete]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.glassPanel]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.grassUncut]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.panel]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.seats]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.stairs]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.plastic]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.trees]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.trapNet]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.flagsImages]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.gradient]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.npc]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.npc1]: {
    ext: 'ktx2',
    version: 240529,
    dir: `${texturesDirKeirin}/env/`
  },
  [TexturesNames.npc2]: {
    ext: 'ktx2',
    version: 240529,
    dir: `${texturesDirKeirin}/env/`
  },
  [TexturesNames.npc3]: {
    ext: 'ktx2',
    version: 240529,
    dir: `${texturesDirKeirin}/env/`
  },
  [TexturesNames.woodFloor]: {
    ext: 'ktx2',
    dir: `${texturesDirKeirin}/env/`
  },
  [TexturesNames.skybox]: {
    ext: 'ktx2',
    version: 241016,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.skyboxBuilding1]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.skyboxBuilding2]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.envLightmap]: {
    ext: 'ktx2',
    dir: `${texturesDirKeirin}/env/`,
    version: 240213,
    returnTextureLightmap: true
  },

}
