<template>
  <div
    v-if="gamePhaseState.showSmallActionButtons"
    class="speed-bar"
  >
    <speed-bar v-if="gamePhaseState.showBar" />
  </div>
</template>

<script lang="ts">
import SpeedBar from './SpeedBar.vue'

import { defineComponent } from 'vue'
import { gamePhaseState } from '@/stores'

export default defineComponent({
  name: 'EfficiencyComponent',
  components: {
    SpeedBar
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  data() {

    return {
      gamePhaseState: gamePhaseState()
    }

  }
})

</script>

<style lang='less' scoped>
.speed-bar {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}
</style>
